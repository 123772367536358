import React from 'react';
import { SVGProps } from 'react';

const SoftballLogoFull = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    xmlSpace="preserve"
    id="Layer_1"
    x={0}
    y={0}
    width="1em"
    height="1em"
    viewBox="125 0 250 100"
    {...props}
  >
    <path
      d="M151 75.9c9.3-15.2 24.7-36.2 31.4-36.2 2.4 0 5 1.6 4.4 3.3-1.4 3.7-1.9 4.3-3.6 7.3-8.3 15.4-11.5 38.2-7.9 40.8 1.3.9 2.7.1 3.5-2.3 3-1.6 9.2 4.1 2.9 9-2.5 2-7.8 1.9-11.5-2.5-6.8-8 .6-33.9 7.9-47.5l-7.3 8c-3.2 3.3-10.8 15.2-12.9 19.3-5 9.4-6.4 19.8-12 15.3-2.9-2.3 1.8-9 5.1-14.5zm.3-16.3c14.3.8 32.4.3 41.3-.3 3.1-.1 3.1 5.5-1.2 4.4 2-.4-34.6-.5-42.2.9-4 .6-1.3-5.1 2.1-5z"
      fill="#333333"
    />
    <path
      d="M191.7 60.1c2.3-4.5 8.5-2.6 6.6 1.7l-1.6 3.6-3.4 9.1c-2.2 3.4-4.1 6.8-5.5 9.5-2.1 4.3-8.6 2.4-6.7-1 4.2-9.2 5.4-12.7 10.6-22.9zm6.1 22.3 8.2-15.8c1.6-3 2.7-4.8.9-3.8-4.1 2.2-14.2 13.1-17.5 18.2l5.1-11.1c5.5-5.8 9.2-9 13.2-11.6 2.7-1.8 7.9 4.7 6.8 7-3 6.4-7.9 13.1-9.2 18.4-1.3 4.3-9.3 2-7.5-1.3zm13.5-14.3c5.5-5.8 7.6-7.1 11.6-9.7 2.7-1.8 7.9 4.7 6.8 7-3.1 6.8-7.5 11.7-8.5 17.4-.4 2.4 15.5-15.9 18.2-19.3.6-.9 1.8.6.9 2-3.3 4.3-11.4 16.4-17.6 21.1-3.6 2.8-10.5-2.4-8.6-6.2l7-13.7c1.5-3 2.7-4.8.9-3.8-4.1 2.2-10 7.2-13.3 12.3l2.6-7.1z"
      fill="#333333"
    />
    <path
      d="M264.2 66.7c-10.9 16-20.5 21.6-26.9 20-7-1.8-6.1-9.1-2.6-16.1 4.2-8.2 8.8-12.6 15-14.3 8-2.2 9.2 6.2 5.6 9.1-6.4 5.2-12.6 5.7-14.7 8.2-1.4 1.5 0-2.1 1.4-3 7.1-4.7 12.6-9.1 9.8-10.5-2.3-1.1-5.8 3.3-9.4 9.9-3.4 6.2-3.4 10.5-1.1 11.4 3 1.1 10.9-3.2 20.3-14.5 2.2-2.7 4.2-2.6 2.6-.2z"
      fill="#333333"
    />
    <path
      d="M261.5 55.5c1.2-2 2.7-2.7 4.1-2.9 2.2-.3 4.7 1.1 3.8 3.7-.5 1.4-1.4 2.7-2 3.6-.7 1.1.4 2 2.2.3 1.2-1.2 2.5-2.6 3.6-2.6 3.3 0 6.1 2.5 5.3 4.3-3.1 6.8-10.4 15.9-11.4 21.6-.4 2.4 17.2-16.7 19.9-20.1.6-.9 1.8.6.9 2-3.7 5.6-13.1 17.1-19.2 21.8-3.6 2.8-10-2.1-8.6-6.2 2.7-7.4 7.2-11.9 10.8-18.6-1.2 1-5.7 1.3-7.3.7-3.2-1.1-3.3-5.9-2.1-7.6zm7.2 7c-3 1.1-4.3 4.5-6.9 7.4-1 .8-2.8 0-1.3-2.1 2.3-2.6 1.8-5.7 4.4-7.8 1.3-1.1-.3-.4 1.2.8.6.6 2.8 1.5 2.6 1.7z"
      fill="#333333"
    />
    <path
      d="M282.9 68.4c2.3-2.6 5.4-7.5 8-9.6 2.5-2.1 8.6.5 5.8 5.9-3.4 6.7-7.8 12.4-8.8 18.1-.4 2.4 15.5-15.9 18.2-19.3.6-.9 1.8.6.9 2-3.3 4.3-11.4 16.4-17.6 21.1-3.6 2.8-10.5-2.4-8.6-6.2l7-13.7c1.5-3 1.7-3.4 1.5-3.1-1.9 2.4-3.4 4.5-5.5 7.4-1 .7-2.4-.4-.9-2.6zm15.3-20.1c2.5.2 3.6 2 2.7 4.3-1.1 2.5-2.5 3.2-5.7 2.8-2.3-.2-3-2.3-2.3-3.7 1.1-2.4 2.5-3.7 5.3-3.4z"
      fill="#333333"
    />
    <path
      d="M328.7 63.7c1.2-.9 1.7.2 1.5 1.2-.1.4-2.4 3.3-2.8 3.8-12.1 16.6-17.9 19.5-24.3 18.3-7.1-1.3-5.1-9.4-1.8-16.5 3.8-8.2 10.3-13.6 16.7-13.7 5.8-.1 6.3 6 3.6 9.6-2.2 3-6.7 1.3-5.6-.8 1.5-2.7 3.7-5.5 2.2-5.6-2.5-.2-5.9 3.3-9.4 9.9-3.4 6.2-3.4 10.5-1.1 11.4 3 1.1 8.2-1.1 17.2-12.9.8-.9 3.4-4.3 3.8-4.7z"
      fill="#333333"
    />
    <path
      d="M341.1 71.4c-11.3 17.5-14.5 17.1-17.7 15.1-5.4-3.3-2.5-10.8 1.4-17.6 4.3-7.5 11.8-11.8 16.7-11.5 7 .5 8.6 4.3 7.5 7.2-1.2 3.2-7.7 2.1-6.8 0 1.4-3.2 1.1-3.9.2-4.3-2.3-.8-7 3.3-10.6 9.9-3.4 6.2-4.4 10.5-2 10.6 1.3.1 4.4-.7 10.7-10.8 1.8-1.8 2.2-1 .6 1.4zm-.2-2c1.2-2.2 7.4-1.5 6.3 1.6-.4 1.1-1.5 2.6-2.7 4.7-2.3 3.9-3.3 7.2-2.7 7.1 2.4-.4 15.5-15.9 18.2-19.3.6-.9 1.8.6.9 2-3.3 4.3-12.1 16.9-18.2 21.7-4.2 3.3-10-3.1-7.9-6.8l4.8-8.6c1-2 .6-1.1 1.3-2.4z"
      fill="#333333"
    />
    <path
      d="M178.5 43.3c-1.7 7.8-9.9 12.7-17.9 12.7-8.3-.1-12.1-5.2-13-9.3-1.1-4.6.4-12.6 8.2-14.1 7.6-1.5 8.2 5.4 5.6 8.4-1.9 2.1-5-.7-6.6.3-.9.5-1.7 1.5-1.8 3-.2 2.8 1.7 7.4 5 8.4 4.1 1.3 8.8-.3 11.6-8.9 3-9.3-9.9-19.4-4.9-30.8 3-7 11-10.6 19.5-9.1 5.5.9 8.6 6.8 7.5 10.1-3.3 10.6-11 3.7-9.3.6C184 12 187 8 184.4 7c-4.6-1.7-10.6-.5-13.4 4.7-3.3 6.5 10 19.7 7.5 31.6zM201.8 21.2c7.1 1.2 7.6 7.7 3.9 16.1-3.7 8.3-11.1 17.1-18.6 15.7-6.9-1.3-6.8-9.1-2.6-18 3.7-8.1 9.8-15.1 17.3-13.8zM191 46.9c2.6 1 6.3-3.1 9.8-9.6 3.5-6.7 4.4-12.7 1.3-12.7-3.7 0-6.2 3.5-9.9 10.4-3.7 6.5-3.6 10.9-1.2 11.9zm26.8-16.2C211 41.1 209 42 205.5 42.8c-6.5 1.6-8.8-5.7-7.8-10.3.7-3.2 2.1-8.8 6.1-8.4l2.1 2.3c-5 5.2-1.6 13.6.6 13 2.1-.6 2.8-1.3 9.9-10.3 1.3-1.3 2.4.4 1.4 1.6z"
      fill="#facd01"
    />
    <path
      d="M214.8 72.3c-3 1.6-7 2.7-7.8-2.5-1.3-8 4-15.5 8.6-26.9 4.5-11.1 13-39.9 22.8-41.7 10.8-2 6.6 8.8 0 15-4.2 4-8.1 8.7-11.9 12.9-3.1 3.5-.9-3.2.2-4 2.4-1.8 8.4-9.1 10.8-11.4 4.3-4.4 5.5-10.9 2.3-9.2-2.3 1.2-12.6 24.3-14.2 28.5-.3 1 12.9 26.5-10.8 39.3zm10.5-50.9c-1.8 2.5-8.2 10.1-10.3 13.1-1.1.9-2.1-.5-.6-2.8 1.9-3 10.6-12.9 12.5-15.5 1.9-2.7.9-.5 1.3-.6l-2.9 5.8zm-10.8 45.4c.1 2.7 9.1-4.7 10.6-10.9 1-4 .3-10-1.3-15.9-8.3 9.9-9.3 25.6-9.3 26.8zm19.1-29.6c2.1-1.8 4.1-4.4 5.6-6.2 1.8-2 2.5-.1 1.8 1-2.9 5-5.1 7.4-11.1 10.1-5.5 2.5-12.4 2.2-10.8-.5 3.5-3.4 9.7-.2 14.5-4.4z"
      fill="#facd01"
    />
    <path
      d="M249.2 12c3.9-1.2 7.7-4.2 7.4.4-.2 3.3-4.7 9.3-9.4 17.5-3.9 6.9-6.4 12.5-7.5 18.5-.5 2.5 16.4-16.8 19.2-20.4.7-.9 1.9.6.9 2.1-3.5 4.6-12 17.2-18.5 22.2-3.8 2.9-11.9.7-7.8-8.2l5.9-12.7c1-2.2 8.3-18.9 9.8-19.4zm-8.6 7.5c11.1.9 9.2.5 16.2-.1 2.4-.1 2.6 5.8-.8 4.7-9.1-.5-7.4-.6-16.8.1-3.2.5-1.2-4.7 1.4-4.7z"
      fill="#facd01"
    />
    <path
      d="M270.4 7.3c2.1-3 8-2.1 6.1 2.5-2.9 6.9-9.4 13.7-13.2 22.1l-.9 6.1c-2.7 4-4.4 8.6-6 11.8-2.2 4.6-9 2.5-7.1-1.1 4.5-9.8 14.3-31.5 21.1-41.4zm-14.5 43.9c-.4-.3-.7-.6-.9-1l2.6-5.6c.6 1.6-.7 3 1.3 3.3 4.7.7 11-8.6 14.7-17.2 1.4-3.2 2.1-5.2.2-4.2-4.7 2.6-9.7 8.4-13 13.8v-.1c.2-.2.3-.5.5-.7l1.2-7.6c5.1-5.3 8.2-7.7 12.2-10.1 2.9-1.8 9 3.9 7.5 7.4-2.3 5.7-4.8 11-7.6 15.2-4.6 6.8-15.6 9.1-18.7 6.8zm14.9-4.6c3.1 7 17.5-13.1 21.4-18 .7-.9 2.1.5 1.1 1.9-3.4 4.6-9.6 16.8-18.7 21-1.5.7-5.7.5-6.8-2.1l3-2.8zM304.8 35c-.2.3-.4.6-.5.8-.1.2-.3.5-.5.9l-5 9.1c-.1.2-.2.3-.2.5 1.9-2.4 4.2-5.7 6.9-9.9 1.6-2.5 1.2-3.4-.7-1.4zm0 0c-.2.3-.4.6-.5.8-.1.2-.3.5-.5.9l-5 9.1c-.1.2-.2.3-.2.5 1.9-2.4 4.2-5.7 6.9-9.9 1.6-2.5 1.2-3.4-.7-1.4z"
      fill="#facd01"
    />
    <path
      d="M326.2 30.1c-3.5 4.6-12.8 17.8-19.2 22.8-4.2 3.3-10-2.7-8.5-6.7 1.9-2.4 4.2-5.7 6.9-9.9 1.7-2.5 1.3-3.3-.6-1.4-.2.3-.4.6-.5.8.5-.9.3-.5.9-1.5 1.3-2.4 7.8-1.6 6.7 1.7-.4 1.2-1.6 2.7-2.9 4.9-2.4 4.2-3.5 7.6-2.9 7.4 2.5-.5 16.4-16.8 19.2-20.4.7-.7 1.9.8.9 2.3z"
      fill="#facd01"
    />
    <path
      d="M313.7 29.2c-1.3 3.4-8.2 2.3-7.2 0 1.4-3.3 1.2-4.2.2-4.5-2.5-.8-7.4 3.5-11.1 10.4-3.6 6.6-4.7 11-2.1 11.2 1.3.1 4.5-.7 10.8-10.5-.1.2-.3.5-.5.9l-5 9.1c-.1.2-.2.3-.2.5-6.5 8.3-9.2 7.7-11.7 6-5.6-3.5-2.7-11.4 1.4-18.5 4.6-7.9 12.4-12.5 17.6-12.1 7.3.5 8.9 4.5 7.8 7.5zM335.6 7.4c1.5-2.1 7.1-2.8 6.7 1.8-.2 3.3-6.6 12.7-11.5 20.7-4.1 6.7-6.4 12.5-7.5 18.5-.5 2.5 16.4-16.8 19.2-20.4.7-.9 1.9.6.9 2.1-3.5 4.6-12 17.2-18.5 22.2-3.8 2.9-11.9.7-7.8-8.2l5.9-12.7c1.1-2.2 11.6-22.7 12.6-24z"
      fill="#facd01"
    />
    <path
      d="M352.8 7.4c1.5-2.1 7.1-2.8 6.7 1.8-.2 3.3-6.6 12.7-11.5 20.7-4.1 6.7-6.4 12.5-7.5 18.5-.5 2.5 16.4-16.8 19.2-20.4.7-.9 1.9.6.9 2.1-3.5 4.6-12 17.2-18.5 22.2-3.8 2.9-11.9.7-7.8-8.2l5.9-12.7c1.1-2.2 11.7-22.7 12.6-24z"
      fill="#facd01"
    />
    <defs>
      <path
        id="SVGID_1_"
        d="M144.4 39.2v60.5h217.3V39.2H144.4zM219.8 69l-.1-.2 1.2-2.2c.2-.5.5-.9.7-1.3.5-1 1-2 1.1-2.4-.1 0-.2.1-.5.2-.6.4-1.4.8-2.1 1.3h-.3c1.8-1.8 3.6-4.1 4.7-6.4.8.2 1.7.7 2.7 1.5-1.5 3.3-3.8 6.6-7.4 9.5z"
      />
    </defs>
    <clipPath id="SVGID_00000096038414114651296080000002389626286633841342_">
      <use
        xlinkHref="#SVGID_1_"
        style={{
          overflow: 'visible',
        }}
      />
    </clipPath>
    <g
      style={{
        clipPath:
          'url(#SVGID_00000096038414114651296080000002389626286633841342_)',
      }}
    >
      <path
        d="M151 75.9c9.3-15.2 24.7-36.2 31.4-36.2 2.4 0 5 1.6 4.4 3.3-1.4 3.7-1.9 4.3-3.6 7.3-8.3 15.4-11.5 38.2-7.9 40.8 1.3.9 2.7.1 3.5-2.3 3-1.6 9.2 4.1 2.9 9-2.5 2-7.8 1.9-11.5-2.5-6.8-8 .6-33.9 7.9-47.5l-7.3 8c-3.2 3.3-10.8 15.2-12.9 19.3-5 9.4-6.4 19.8-12 15.3-2.9-2.3 1.8-9 5.1-14.5zm.3-16.3c14.3.8 32.4.3 41.3-.3 3.1-.1 3.1 5.5-1.2 4.4 2-.4-34.6-.5-42.2.9-4 .6-1.3-5.1 2.1-5z"
        fill="#333333"
      />
      <path
        d="M191.7 60.1c2.3-4.5 8.5-2.6 6.6 1.7l-1.6 3.6-3.4 9.1c-2.2 3.4-4.1 6.8-5.5 9.5-2.1 4.3-8.6 2.4-6.7-1 4.2-9.2 5.4-12.7 10.6-22.9zm6.1 22.3 8.2-15.8c1.6-3 2.7-4.8.9-3.8-4.1 2.2-14.2 13.1-17.5 18.2l5.1-11.1c5.5-5.8 9.2-9 13.2-11.6 2.7-1.8 7.9 4.7 6.8 7-3 6.4-7.9 13.1-9.2 18.4-1.3 4.3-9.3 2-7.5-1.3zm13.5-14.3c5.5-5.8 7.6-7.1 11.6-9.7 2.7-1.8 7.9 4.7 6.8 7-3.1 6.8-7.5 11.7-8.5 17.4-.4 2.4 15.5-15.9 18.2-19.3.6-.9 1.8.6.9 2-3.3 4.3-11.4 16.4-17.6 21.1-3.6 2.8-10.5-2.4-8.6-6.2l7-13.7c1.5-3 2.7-4.8.9-3.8-4.1 2.2-10 7.2-13.3 12.3l2.6-7.1z"
        fill="#333333"
      />
      <path
        d="M264.2 66.7c-10.9 16-20.5 21.6-26.9 20-7-1.8-6.1-9.1-2.6-16.1 4.2-8.2 8.8-12.6 15-14.3 8-2.2 9.2 6.2 5.6 9.1-6.4 5.2-12.6 5.7-14.7 8.2-1.4 1.5 0-2.1 1.4-3 7.1-4.7 12.6-9.1 9.8-10.5-2.3-1.1-5.8 3.3-9.4 9.9-3.4 6.2-3.4 10.5-1.1 11.4 3 1.1 10.9-3.2 20.3-14.5 2.2-2.7 4.2-2.6 2.6-.2z"
        fill="#333333"
      />
      <path
        d="M261.5 55.5c1.2-2 2.7-2.7 4.1-2.9 2.2-.3 4.7 1.1 3.8 3.7-.5 1.4-1.4 2.7-2 3.6-.7 1.1.4 2 2.2.3 1.2-1.2 2.5-2.6 3.6-2.6 3.3 0 6.1 2.5 5.3 4.3-3.1 6.8-10.4 15.9-11.4 21.6-.4 2.4 17.2-16.7 19.9-20.1.6-.9 1.8.6.9 2-3.7 5.6-13.1 17.1-19.2 21.8-3.6 2.8-10-2.1-8.6-6.2 2.7-7.4 7.2-11.9 10.8-18.6-1.2 1-5.7 1.3-7.3.7-3.2-1.1-3.3-5.9-2.1-7.6zm7.2 7c-3 1.1-4.3 4.5-6.9 7.4-1 .8-2.8 0-1.3-2.1 2.3-2.6 1.8-5.7 4.4-7.8 1.3-1.1-.3-.4 1.2.8.6.6 2.8 1.5 2.6 1.7z"
        fill="#333333"
      />
      <path
        d="M282.9 68.4c2.3-2.6 5.4-7.5 8-9.6 2.5-2.1 8.6.5 5.8 5.9-3.4 6.7-7.8 12.4-8.8 18.1-.4 2.4 15.5-15.9 18.2-19.3.6-.9 1.8.6.9 2-3.3 4.3-11.4 16.4-17.6 21.1-3.6 2.8-10.5-2.4-8.6-6.2l7-13.7c1.5-3 1.7-3.4 1.5-3.1-1.9 2.4-3.4 4.5-5.5 7.4-1 .7-2.4-.4-.9-2.6zm15.3-20.1c2.5.2 3.6 2 2.7 4.3-1.1 2.5-2.5 3.2-5.7 2.8-2.3-.2-3-2.3-2.3-3.7 1.1-2.4 2.5-3.7 5.3-3.4z"
        fill="#333333"
      />
      <path
        d="M328.7 63.7c1.2-.9 1.7.2 1.5 1.2-.1.4-2.4 3.3-2.8 3.8-12.1 16.6-17.9 19.5-24.3 18.3-7.1-1.3-5.1-9.4-1.8-16.5 3.8-8.2 10.3-13.6 16.7-13.7 5.8-.1 6.3 6 3.6 9.6-2.2 3-6.7 1.3-5.6-.8 1.5-2.7 3.7-5.5 2.2-5.6-2.5-.2-5.9 3.3-9.4 9.9-3.4 6.2-3.4 10.5-1.1 11.4 3 1.1 8.2-1.1 17.2-12.9.8-.9 3.4-4.3 3.8-4.7z"
        fill="#333333"
      />
      <path
        d="M341.1 71.4c-11.3 17.5-14.5 17.1-17.7 15.1-5.4-3.3-2.5-10.8 1.4-17.6 4.3-7.5 11.8-11.8 16.7-11.5 7 .5 8.6 4.3 7.5 7.2-1.2 3.2-7.7 2.1-6.8 0 1.4-3.2 1.1-3.9.2-4.3-2.3-.8-7 3.3-10.6 9.9-3.4 6.2-4.4 10.5-2 10.6 1.3.1 4.4-.7 10.7-10.8 1.8-1.8 2.2-1 .6 1.4zm-.2-2c1.2-2.2 7.4-1.5 6.3 1.6-.4 1.1-1.5 2.6-2.7 4.7-2.3 3.9-3.3 7.2-2.7 7.1 2.4-.4 15.5-15.9 18.2-19.3.6-.9 1.8.6.9 2-3.3 4.3-12.1 16.9-18.2 21.7-4.2 3.3-10-3.1-7.9-6.8l4.8-8.6c1-2 .6-1.1 1.3-2.4z"
        fill="#333333"
      />
    </g>
  </svg>
);

export default SoftballLogoFull;
