import { Add, Star } from '@mui/icons-material';
import { Rating } from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import { RankingType } from '@on3/api';
import { On3IconTransferRating } from '@on3/icons';
import { useSite } from '@on3/ui-lib/src/contexts/SiteProvider';
import clsx from 'clsx';
import { ReactNode, useCallback } from 'react';
import { slugify } from 'utils/slugify';

import { ITextLink, TextLink } from '../Link/TextLink';
import { Text } from '../Typography/Text';
import styles from './StarRating.module.scss';

// TODO: redo this component.
interface IStarRating {
  className?: string;
  /** type of rating, (T) - transfer, (HS) - High school, etc. */
  ratingType?: string;
  large?: boolean;
  small?: boolean;
  fiveStarPlus?: boolean;
  isDark?: boolean;
  /** Use this for a fixed two decimal places on rating */
  // TODO: we need to update this to isIndustry
  isConsensus?: boolean;
  /** We use this to have a custom rounding **/
  rounding?: number;
  href?: string;
  isBoldRating?: boolean;
  grayRating?: boolean;
  /** Team logo object with src and altText props **/
  teamLogo?: { src: string; altText: string };
  hasBorder?: boolean;
  position?: string | null;
  sport?: string | null;
  year?: string | null;
  state?: string | null;
  type?: string | null;
  rating?: number | null;
  stars?: number | null;
  nationalRank?: number | null;
  positionRank?: number | null;
  stateRank?: number | null;
  rankingType?: RankingType;
  primaryColor?: string | null;
}

interface IConditionalWrapper extends ITextLink {
  condition: boolean;
  href?: string;
  children: ReactNode;
  className?: string;
}

const CustomRating = withStyles({
  iconFilled: {
    color: '#f2c94c',
  },
  iconEmpty: {
    color: 'rgba(0, 0, 0, 0.12)',
  },
})(Rating);

const darkEmptyStarStyle = { color: 'rgba(255, 255, 255, .2)' };
const emptyStarStyle = { color: 'rgba(0, 0, 0, 0.12)' };

const LinkComponent = ({
  children,
  href,
  className,
  variant,
  ...restProps
}: ITextLink) => (
  <TextLink
    className={clsx(className, styles.linkWrapper)}
    href={href || ''}
    underline="none"
    variant={variant ?? 'body1'}
    {...restProps}
  >
    {children}
  </TextLink>
);

export const ConditionalLink = ({
  condition,
  href,
  children,
  className,
  variant,
  ...restProps
}: IConditionalWrapper) =>
  condition ? (
    <LinkComponent className={className} href={href} {...restProps}>
      {children}
    </LinkComponent>
  ) : (
    <Text className={className} component="span" variant={variant ?? 'body1'}>
      {children}
    </Text>
  );

export const StarRating = ({
  className,
  state,
  position,
  sport,
  year,
  fiveStarPlus,
  large,
  small,
  nationalRank,
  positionRank,
  rating,
  ratingType = '',
  stars,
  stateRank,
  isConsensus,
  isDark,
  href,
  rounding = 2,
  isBoldRating = true,
  grayRating = false,
  hasBorder = true,
  teamLogo,
  type,
  rankingType,
  primaryColor,
}: IStarRating) => {
  const hasRankings = nationalRank || positionRank || stateRank;
  const { sportsData } = useSite();

  const isIndustrySport = sportsData?.find(
    (sportData) => slugify(sportData.name || '') === sport,
  )?.isIndustryRankable;
  const rankingTypeUrl =
    isConsensus && isIndustrySport ? 'industry-player' : 'player';
  const sluggedSport = slugify(sport || 'football');
  const fiveStarPlusLinkCheck = fiveStarPlus && year && sluggedSport;
  const fiveStarPlusLink = `/db/rankings/industry-five-stars/${sluggedSport}/${year}/`;
  const linkColor = primaryColor || '#fe3b1f';
  const setRating: (num: any) => string = useCallback(
    (num) => {
      let adjustedValue = num;

      if (isConsensus) {
        adjustedValue = num.toFixed(rounding);
      }

      if (type?.toLowerCase() === 'rivals') {
        adjustedValue = adjustedValue?.toFixed(1);
      }

      return adjustedValue;
    },
    [isConsensus, rounding, type],
  );

  const sluggedState = slugify(state || '');
  const sluggedPosition = slugify(position || '');

  return (
    <div className={clsx(className, styles.starRating)}>
      <div className={styles.starWrapper}>
        <ConditionalLink
          className={styles.starWrapper}
          condition={!!href}
          href={href}
        >
          <CustomRating
            className={clsx(styles.star, {
              [styles.large]: large,
              [styles.small]: small,
            })}
            defaultValue={stars || 0}
            emptyIcon={
              <Star
                fontSize="inherit"
                style={isDark ? darkEmptyStarStyle : emptyStarStyle}
              />
            }
            key={stars}
            readOnly
            size={large ? 'medium' : 'small'}
          />
        </ConditionalLink>

        {fiveStarPlusLinkCheck && (
          <TextLink
            className={styles.starWrapper}
            href={fiveStarPlusLink}
            underline="none"
          >
            <Add className={styles.consesusFiveStar} />
          </TextLink>
        )}

        {!!rating && (
          <ConditionalLink condition={!!href} href={href}>
            <span
              className={clsx(styles.overallRating, {
                [styles.bolded]: isBoldRating,
                [styles.border]: hasBorder,
                [styles.gray]: grayRating,
                [styles.small]: small,
              })}
            >
              {setRating(rating)}
              {ratingType && ` (${ratingType})`}
            </span>
          </ConditionalLink>
        )}
        {teamLogo && (
          <img
            alt={teamLogo.altText || 'Team Logo'}
            className={styles.teamLogo}
            height="20"
            src={teamLogo.src}
            width="20"
          />
        )}
        {rankingType === RankingType.TransferPortal ? (
          <On3IconTransferRating />
        ) : null}
      </div>
      {!!hasRankings && (
        <dl className={styles.ranksWrapper}>
          <dd aria-label="national rank" className={styles.ranksLabel}>
            NATL
          </dd>
          <dt className={styles.ranksNumberWrapper}>
            <ConditionalLink
              className={styles.ranksNumber}
              condition={!!(sport && year)}
              href={`/db/rankings/${rankingTypeUrl}/${sluggedSport}/${year}/`}
              style={{ color: linkColor }}
            >
              {nationalRank || '-'}
            </ConditionalLink>
          </dt>
          <dd aria-label="position rank" className={styles.ranksLabel}>
            POS
          </dd>
          <dt className={styles.ranksNumberWrapper}>
            <ConditionalLink
              className={styles.ranksNumber}
              condition={!!(sport && year && position)}
              href={`/db/rankings/${rankingTypeUrl}/${sluggedSport}/${year}/?position=${sluggedPosition}`}
              style={{ color: linkColor }}
            >
              {positionRank || '-'}
            </ConditionalLink>
          </dt>
          <dd aria-label="state rank" className={styles.ranksLabel}>
            ST
          </dd>
          <dt className={styles.ranksNumberWrapper}>
            <ConditionalLink
              className={styles.ranksNumber}
              condition={!!(sport && year && state)}
              href={`/db/rankings/${rankingTypeUrl}/${sluggedSport}/${year}/?state=${sluggedState}`}
              style={{ color: linkColor }}
            >
              {stateRank || '-'}
            </ConditionalLink>
          </dt>
        </dl>
      )}
    </div>
  );
};
