import * as React from 'react';
import { SVGProps } from 'react';

const SvgTransferRating = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" width={16} height={8} fill="none">
    <path
      fill="#FE3B1F"
      d="M3.935 8V1.5h-3v-1h7.15v1h-3V8h-1.15Zm6.032 0h-1.15V4.26c0-.18.06-.32.19-.43.12-.1.27-.16.44-.16h3.43c.43 0 .73-.06.9-.2.17-.13.26-.38.26-.74v-.31c0-.36-.09-.6-.26-.73-.17-.12-.47-.19-.9-.19h-6.09v-1h5.84c.36 0 .7.03 1.02.09.32.06.59.17.83.31.23.15.41.35.54.6.13.25.2.56.2.94v.27c0 .76-.24 1.28-.7 1.54-.46.27-1.11.4-1.94.4h-.62L15.207 8h-1.51l-3.27-3.35h-.46V8Z"
    />
  </svg>
);

export default SvgTransferRating;
